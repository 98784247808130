import { Button, Grid, GridItem, HStack } from "@chakra-ui/react";
import Link from "components/Link";
import Logo from "components/Logo";
import { useTranslation } from "next-i18next";
import React from "react";
import { LANGUAGES, LINKS } from "utils";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import LangButton from "./LangButtons";

function DesktopHeader() {
  const { t } = useTranslation();
  const { onOpen } = useContactMeModal();

  return (
    <Grid
      alignItems="center"
      templateColumns="1fr auto 1fr"
      paddingTop="3.2rem"
    >
      <GridItem mt="1.8rem">
        <HStack spacing="4rem">
          {LINKS.map(({ t, url }) => (
            <Link key={t} text={t} url={url} />
          ))}
        </HStack>
      </GridItem>
      <GridItem>
        <Logo />
      </GridItem>
      <GridItem display="flex" alignItems="center" ml="auto" mt="1.8rem">
        <HStack spacing="1.6rem" mr="4rem">
          {LANGUAGES.map(({ label, locale }) => (
            <LangButton key={locale} label={label} locale={locale} />
          ))}
        </HStack>
        <Button variant="outline" onClick={onOpen} aria-label="Contact me">
          {t("header.nav.contact_me")}
        </Button>
      </GridItem>
    </Grid>
  );
}

export default DesktopHeader;
