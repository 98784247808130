import { Box, Slide, useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useEffect, useLayoutEffect, useState } from "react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import * as CSS from "csstype";
import { HEADER_ID } from "utils";
import Layout from "components/Layout";
import useHeaderHeight from "hooks/useHeaderHeight";
import { useScroll } from "hooks/useScroll";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import { useMediaQuery } from 'hooks/useMediaQuery';

const StyledSlide = styled(Slide)`
  z-index: 100;
`;

interface Props {
  bg?: string;
}

const Header = ({ bg = "bg.beige" }: Props) => {
  const [renderedComponent, setRenderedComponent] = useState(<MobileHeader />);
  const moreThan1060 = useMediaQuery("(min-width: 1060px)");
  const headerHeight = useHeaderHeight();
  const { dialog } = useContactMeModal();

  const { isOpen: showHeader, onToggle: toggleShowHeader } = useDisclosure({
    defaultIsOpen: true,
  });

  const scroll = useScroll({
    wait: 300,
  });

  useLayoutEffect(() => {
    if (
      scroll.direction === "down" &&
      showHeader &&
      scroll?.y &&
      scroll.y > 100
    ) {
      toggleShowHeader();
    }

    if (scroll.direction === "up" && !showHeader) {
      toggleShowHeader();
    }
  }, [scroll.direction, showHeader, toggleShowHeader, scroll.y]);

  useEffect(() => {
    setRenderedComponent(moreThan1060 ? <DesktopHeader /> : <MobileHeader />);
  }, [moreThan1060]);

  return (
    <>
      <Box
        w="100%"
        zIndex={100}
        as="header"
        pb={headerHeight || 0}
        backgroundColor={bg}
      >
        <StyledSlide in={showHeader} direction="top" id={HEADER_ID}>
          <Box backgroundColor={bg} pb="1.6rem">
            <Layout>{renderedComponent}</Layout>
          </Box>
        </StyledSlide>
      </Box>
      {dialog}
    </>
  );
};

export default Header;
