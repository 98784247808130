import { Text, Link as ChakraLink } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import { colors } from "theme/colors";
interface LinkProps {
  text?: string;
  url?: string;
  onClick?: () => void;
  color?: "white" | "text.dark";
  children?: React.ReactNode;
  scroll?: boolean;
  asText?: boolean;
  isActive?: boolean;
}

function Link({
  text,
  url,
  color = "text.dark",
  children,
  scroll = true,
  onClick,
  isActive,
}: LinkProps) {
  const { t } = useTranslation();

  if (!url || onClick) {
    return (
      <Text
        textStyle="b20"
        whiteSpace="nowrap"
        color={isActive ? colors.main.green : color}
        cursor="pointer"
        _hover={{ color: colors.text.light_green }}
        transitionDuration="var(--chakra-transition-duration-fast)"
        onClick={onClick}
      >
        {children || t(text as any)}
      </Text>
    );
  }

  return (
    <NextLink href={url} passHref scroll={scroll} shallow legacyBehavior>
      <ChakraLink
        textStyle="b20"
        whiteSpace="nowrap"
        color={isActive ? colors.main.green : color}
        _hover={{
          textDecoration: "none",
          color: colors.text.light_green,
        }}
      >
        {children || t(text as any)}
      </ChakraLink>
    </NextLink>
  );
}

export default Link;
