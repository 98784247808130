import { Container, ContainerProps } from "@chakra-ui/react";
import React from "react";

interface Props extends ContainerProps {
  children: React.ReactNode;
}
const Layout = ({ children, ...rest }: Props) => {
  return (
    <Container maxW="114rem" p={{ base: "0 3.2rem", moreThan769: "0 1rem" }} {...rest}>
      {children}
    </Container>
  );
};

export default Layout;
