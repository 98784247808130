import React from "react";
import LogoIcon from "@img/logo.png";
import { Flex, Image, Text } from "@chakra-ui/react";
import Link from "next/link";

interface Props {
  color?: "white";
}
function Logo({ color }: Props) {
  return (
    <Link href="/" passHref legacyBehavior>
      <a>
        <Flex direction="column" alignItems="center">
          <Image src={LogoIcon.src} alt="Logo" width="8.6rem" h="4rem" />
          <Text
            fontFamily="Cormorant"
            fontSize="1.8rem"
            fontWeight={700}
            textTransform="uppercase"
            color={color || "black"}
            mb="2px"
          >
            Daria Romanovska
          </Text>
          <Text
            fontFamily="Futura"
            fontSize="1.4rem"
            color={color || "main.green"}
            lineHeight="1.6rem"
          >
            Nutritionist & Health coach
          </Text>
        </Flex>
      </a>
    </Link>
  );
}

export default Logo;
