import { useEffect, useState } from "react";
import { HEADER_ID, isSSR } from "utils";

export default function useHeaderHeight() {
  const [headerHeight, setHeaderHeight] = useState<number | null>(null);

  useEffect(() => {
    if (!isSSR && !headerHeight) {
      const header = document.getElementById(HEADER_ID);
      if (header?.clientHeight) {
        setHeaderHeight(header.clientHeight + 16);
      }
    }
  }, [headerHeight]);

  return headerHeight;
}
