import { Box, Flex, VStack } from "@chakra-ui/react";
import Link from "components/Link";
import Logo from "components/Logo";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import React from "react";
import { LINKS } from "utils";
import { Navigation } from "utils/enum";

const MobileFooter = () => {
  const { onOpen } = useContactMeModal();

  return (
    <Flex direction="column" alignItems="center">
      <Box mb="3.2rem">
        <Logo color="white" />
      </Box>
      <VStack spacing="2.4rem">
        {LINKS.map(({ t, url }) => (
          <Link key={t} text={t} url={url} color="white" />
        ))}
        <Link text={"footer.nav.contact_me"} onClick={onOpen} color="white" />
        <Link
          text={"footer.nav.terms_conditions"}
          color="white"
          url={"/" + Navigation.TermsAndConditions}
        />
      </VStack>
    </Flex>
  );
};

export default MobileFooter;
