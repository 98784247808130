import Head from "next/head";
import React from "react";
import { LANGUAGES } from "utils";

interface Props {
  url: string;
  children?: React.ReactNode;
  locale?: string;
}

const MetaLocaleLinks = ({ url, children, locale }: Props) => {
  const NEXT_PUBLIC_APP_URL = process.env.NEXT_PUBLIC_APP_URL;

  return (
    <Head>
      {children}
      <link
        rel="canonical"
        href={`${NEXT_PUBLIC_APP_URL}/${locale}${url ? "/" + url : url}`}
      />
      {LANGUAGES.map(({ locale }) => {
        const alternalteUrl = `${NEXT_PUBLIC_APP_URL}/${locale}${
          url ? "/" + url : url
        }`;

        return (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={alternalteUrl}
          />
        );
      })}
    </Head>
  );
};

export default MetaLocaleLinks;
