import React from "react";
import { Box, Center, Container, HStack } from "@chakra-ui/react";
import Logo from "components/Logo";
import { LINKS } from "utils";
import Link from "components/Link";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import { Navigation } from 'utils/enum';

const DesktopFooter = () => {
  const { onOpen } = useContactMeModal();
  return (
    <Container maxW="59.6rem">
      <Box mb="6rem">
        <Center>
          <Logo color="white" />
        </Center>
      </Box>
      <HStack spacing="4rem">
        {LINKS.map(({ t, url }) => (
          <Link key={t} text={t} url={url} color="white" />
        ))}
        <Link text={"footer.nav.contact_me"} onClick={onOpen} color="white" />
      </HStack>
      <Box textAlign="center" mt="2rem">
        <Link
          text={"footer.nav.terms_conditions"}
          color="white"
          url={'/' + Navigation.TermsAndConditions}
        />
      </Box>
    </Container>
  );
};

export default DesktopFooter;
