import { Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { colors } from "theme/colors";

interface ButtonProps {
  label: string;
  locale: string;
}
function LangButton({ label, locale }: ButtonProps) {
  const { push, pathname, asPath, query, locale: activeLocale } = useRouter();

  return (
    <Text
      fontSize="1.6rem"
      fontFamily="Cormorant"
      fontWeight={700}
      color={activeLocale === locale ? "main.green" : "text.grey"}
      _hover={{
        color: colors.text.light_green,
      }}
      transitionDuration="var(--chakra-transition-duration-fast)"
      as="button"
      onClick={() => {
        push({ pathname, query }, asPath, { locale });
      }}
    >
      {label}
    </Text>
  );
}

export default LangButton;
