import React, { useEffect, useLayoutEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import DesktopFooter from "./Desktop";
import MobileFooter from "./Mobile";
import { useMediaQuery } from 'hooks/useMediaQuery';

function Footer() {
  const [renderedComponent, setRenderedComponent] = useState(<MobileFooter />);
  const moreThan768 = useMediaQuery("(min-width: 768px)");

  useLayoutEffect(() => {
    setRenderedComponent(moreThan768 ? <DesktopFooter /> : <MobileFooter />);
  }, [moreThan768]);

  return (
    <Box
      backgroundColor="#333C25"
      className="content-visibility"
      bgImage={{
        base: "url('/img/footer4.svg'), url(/img/footer3.svg)",
        xl: "url('/img/footer1.svg'), url(/img/footer2.svg)",
      }}
      bgRepeat="no-repeat"
      bgPosition={{
        base: "bottom left, bottom right",
      }}
      p={{ base: "3.2rem 0", md: "6.4rem 0 7.2rem" }}
    >
      {renderedComponent}
    </Box>
  );
}

export default Footer;
