import { Box, Button, Flex, HStack, Slide, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "components/Logo";
import Menu from "@img/menu.svg";
import Close from "@img/close.svg";
import styled from "@emotion/styled";
import { useLockBodyScroll } from "hooks/useLockBodyScroll";
import LangButton from "./LangButtons";
import { LANGUAGES, LINKS } from "utils";
import { useTranslation } from "next-i18next";
import Link from "components/Link";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import { Portal } from "@chakra-ui/react";

const StyledSlide = styled(Slide)`
  display: flex;
  flex-direction: column;
  justify-items: center;
  z-index: 1000;
  background: white;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  height: var(--app-height);
  overflow: auto;
  gap: 2rem;
`;

const MobileHeader = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const { onOpen } = useContactMeModal();

  useLockBodyScroll({ block: show });

  const handleClose = () => setShow(false);

  const handleContactMe = () => {
    onOpen();
    handleClose();
  };
  return (
    <>
      <LogoAndMenu>
        <Menu onClick={() => setShow(true)} />
      </LogoAndMenu>
      {/* Opened */}
      <Portal>
        <StyledSlide direction="right" in={show}>
          <LogoAndMenu right="2.4rem">
            <Close onClick={handleClose} />
          </LogoAndMenu>
          <VStack spacing="4rem" justifyContent="center" flexGrow={1}>
            {LINKS.map(({ t, url }) => (
              <Box key={t} onClick={handleClose}>
                <Link key={t} text={t} url={url} />
              </Box>
            ))}
          </VStack>
          <Flex mt="auto" alignItems="center" flexDir="column" mb="3.2rem">
            <HStack spacing="3.2rem" mb="4rem">
              {LANGUAGES.map(({ label, locale }) => (
                <LangButton key={locale} label={label} locale={locale} />
              ))}
            </HStack>
            <Button
              variant="outline"
              w="31rem"
              onClick={handleContactMe}
              aria-label="Contact me"
            >
              {t("welcome.contact_me")}
            </Button>
          </Flex>
        </StyledSlide>
      </Portal>
    </>
  );
};

interface LogoAndMenuProps {
  children: React.ReactNode;
  right?: string;
}
const LogoAndMenu = ({ children, right = "0" }: LogoAndMenuProps) => {
  return (
    <Flex justifyContent="center" pt="1.6rem" position="relative">
      <Logo />
      <Box
        position="absolute"
        right={right}
        top="50%"
        transform="translateY(-50%)"
        cursor="pointer"
      >
        {children}
      </Box>
    </Flex>
  );
};
export default MobileHeader;
